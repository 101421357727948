<template>
    <div>
        <!-- <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item  label="">
                        <el-radio v-model="filters.reportType" label="2">月报</el-radio>
                        <el-radio v-model="filters.reportType" label="1">周报</el-radio>
                    </el-form-item>
                    <el-form-item  label="日期:" v-if="filters.reportType == '2'">
                        <el-date-picker
                            v-model="filters.monthData"
                            type="year"
                            placeholder="选择年"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item  label="日期:" v-if="filters.reportType == '1'">
                        <el-date-picker
                            v-model="filters.monthData"
                            type="monthrange"
                            range-separator="至"
                            start-placeholder="开始月份"
                            end-placeholder="结束月份"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row> -->
        <!-- 详情 -->
        <div class="examDetailsBox">
            <div>
                <div class="detailTitle">考试总人数</div>
                <div class="detailNumber">{{detailsData.ExamTotal ? detailsData.ExamTotal : 0}}</div>
                <div>免培 {{detailsData.ExamTotalOfNoTrain ? detailsData.ExamTotalOfNoTrain : 0}}</div>
                <div>非免培 {{detailsData.ExamTotalOfTrain ? detailsData.ExamTotalOfTrain : 0}}</div>
            </div>
            <div>
                <div class="detailTitle">正常交卷数</div>
                <div class="detailNumber">{{detailsData.NormalPapersTotal ? detailsData.NormalPapersTotal : 0}}</div>
                <div>免培 {{detailsData.NormalPapersTotalOfNoTrain ? detailsData.NormalPapersTotalOfNoTrain : 0}}</div>
                <div>非免培 {{detailsData.NormalPapersTotalOfTrain ? detailsData.NormalPapersTotalOfTrain : 0}}</div>
            </div>
            <div>
                <div class="detailTitle">异常交卷数</div>
                <div class="detailNumber">{{detailsData.ExceptionPapersTotal ? detailsData.ExceptionPapersTotal : 0}}</div>
                <div>其他 {{detailsData.ExceptionPapersTotalOfOther ? detailsData.ExceptionPapersTotalOfOther : 0}}</div>
                <div>达到违规数 {{detailsData.ExceptionPapersTotalOfViolation ? detailsData.ExceptionPapersTotalOfViolation : 0}}</div>
                <div>切屏或锁屏 {{detailsData.ExceptionPapersTotalOfCutOrLockScreen ? detailsData.ExceptionPapersTotalOfCutOrLockScreen : 0}}</div>
            </div>
            <div>
                <div class="detailTitle">及格人数</div>
                <div class="detailNumber">{{detailsData.QualifiedTotal ? detailsData.QualifiedTotal : 0}}</div>
                <div>免培 {{detailsData.QualifiedTotalOfNoTrain ? detailsData.QualifiedTotalOfNoTrain : 0}}; {{detailsData.PassRateOfNoTrain ? redefinitionDecimalFun(detailsData.PassRateOfNoTrain) : 0}}</div>
                <div>非免培 {{detailsData.QualifiedTotalOfTrain ? detailsData.QualifiedTotalOfTrain : 0}}; {{detailsData.PassRateOfTrain ? redefinitionDecimalFun(detailsData.PassRateOfTrain) : 0}}</div>
                <div>及格率 {{detailsData.PassRate ? redefinitionDecimalFun(detailsData.PassRate) : 0}}</div>
                <div>正常交卷及格率 {{detailsData.NormalPapersQualifiedPassRate ? redefinitionDecimalFun(detailsData.NormalPapersQualifiedPassRate) : 0}}</div>
            </div>
            <div>
                <div class="detailTitle">取证人数</div>
                <div class="detailNumber">{{detailsData.CertificateTotal ? detailsData.CertificateTotal : 0}}</div>
                <div>免培被驳回 {{detailsData.NoTrainOfReject ? detailsData.NoTrainOfReject : 0}}</div>
                <div>非免培被驳回 {{detailsData.TrainOfReject ? detailsData.TrainOfReject : 0}}</div>
                <div>取证率 {{detailsData.CertificateRate ? redefinitionDecimalFun(detailsData.CertificateRate) : 0}}</div>
            </div>
        </div>
        <!-- 列表 -->
        <div class="timerSelect">
            <el-date-picker
                v-model="filters.timerData"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="{
                    disabledDate: (time) => {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24 * 62  || time.getTime() > Date.now() - 3600 * 1000 * 24
                    }
                }"
            >
            </el-date-picker>
        </div>
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            @current-change='currentChange'
            :highlight-current-row='true'
            :span-method="objectSpanMethod"
            :cell-style='rowClass'
        >
            <!-- <el-table-column type='index' label="序号" width='60' align="center"></el-table-column> -->
            <el-table-column prop="Date" label="日期" width="120" align="center">
                <template scope="scope">
                    <span>{{scope.row.Date ? formatCreateTimeDay1(scope.row.Date) : "合计"}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="ReportObjectName" label="类型" min-width="80" align="center" show-overflow-tooltip>
                <template scope="scope">
                    <span type='' v-if='scope.row.Type == 0'>免培</span>
                    <span type='success' v-else-if='scope.row.Type == 1'>非免培</span>
                    <span type='success' v-else-if='scope.row.Type == 2'>合计</span>
                    <span type='danger' v-else>未知</span>
                </template>
            </el-table-column>
            <el-table-column prop="ExamTotal" label="考试总人数" min-width="100" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="NormalPapersTotal" label="正常交卷数" min-width="100" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="ExceptionPapersTotal" label="异常交卷数" min-width="100" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="QualifiedTotal" label="及格人数" min-width="90" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="QualifiedRate" label="及格率" min-width="80" align="center" show-overflow-tooltip>
                <template scope="scope">
                    {{redefinitionDecimalFun(scope.row.QualifiedRate)}}
                </template>
            </el-table-column>
            <el-table-column prop="NormalPapersQualifiedRate" label="正常交卷及格率" min-width="130" align="center" show-overflow-tooltip>
                <template scope="scope">
                    {{redefinitionDecimalFun(scope.row.NormalPapersQualifiedRate)}}
                </template>
            </el-table-column>
            <el-table-column prop="CertificateTotal" label="取证人数" min-width="90" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="CertificateRate" label="取证率" min-width="80" align="center" show-overflow-tooltip>
                <template scope="scope">
                    {{redefinitionDecimalFun(scope.row.CertificateRate)}}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getExaminationSituationAnalysisTotal,getExaminationSituationAnalysisDetail, } from '@/api/api'
import Qs from 'qs'
export default {
    components:{Toolbar},
    data() {
        return {
            buttonList: [],
            needData: '',
            filters:{
                timerData: [],
            },
            tableData: [],
            listLoading: false,
            detailsData: "",
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        getDataList(){
            this.listLoading = true
            var params = {
                startDate: this.formatCreateTimeDay1(this.filters.timerData[0]),
                endDate: this.formatCreateTimeDay1(this.filters.timerData[1]),
            }
            getExaminationSituationAnalysisDetail(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.listLoading = false;
                    this.tableData = result.Response.reverse()
                    // this.tableData = this.moveLastThreeToFront(result.Response)
                }else{
                    this.listLoading = false
                }
            })
        },
        moveLastThreeToFront(arr) {
            const lastThree = arr.slice(-3);  // 获取数组的最后三个元素
            const rest = arr.slice(0, -3);    // 获取数组的其余部分
            return lastThree.concat(rest);    // 将最后三个元素拼接到前面
        },
        getDetailsData(){
            var params = {}
            getExaminationSituationAnalysisTotal(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.detailsData = result.Response
                }
            })
        },
        // 查询
        getBriefingListData() {
            this.getDataList();
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if(row.Date) {
                if (columnIndex === 0) {
                        if (rowIndex % 2 === 1) {
                            return {
                                rowspan: 2,
                                colspan: 1
                            };
                        } else {
                            return {
                                rowspan: 0,
                                colspan: 0
                            };
                        }
                }
            } else {
                if (columnIndex === 0) {
                    if (rowIndex === 0) {
                        return {
                            rowspan: 3,
                            colspan: 1
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            }
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd");
        },
        // 筛选项input框清空
        handleClear(){
            
        },
        // 获取当前时间
        getLastSomeDay() {
            var currentDate = new Date();  // 获取当前时间
            var previousDate = new Date(currentDate);  // 复制当前时间对象
            var preSevDate = new Date(currentDate);  // 复制当前时间对象
            preSevDate.setDate(currentDate.getDate() - 10);
            previousDate.setDate(currentDate.getDate() - 1);
            this.filters.timerData = [preSevDate,previousDate]
        },
        formatCreateTimeDay1: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd");
        },
        getMonthFirstOrEndDay(type,val) {
            // 获取当月的第一天
            if(type == 1) {
                var date = val
                date.setDate(1)
                return date.toLocaleDateString()
            }
            // 获取当月的最后一天
            if(type == 2) {
                const date = new Date(val);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                return year + '-' + month + '-' + new Date(year, month, 0).getDate();
            }
            // 获取当年第一天
            if(type == 3) {
                var date = val
                return this.formatCreateTimeDay1(date).split("-")[0] + "-01-01"
            }
            // 获取当年最后一天
            if(type == 4) {
                var date = val
                return this.formatCreateTimeDay1(date).split("-")[0] + "-12-31"
            }
        },
        rowClass({columnIndex}){
            return 'padding:8px 0!important;'
        },
        redefinitionDecimalFun(val) {
			if(val && val > 0) {
				if(val < 0.01) {
					return 0.1 + '%'
				} else {
					var num = val
					// 不四舍五入向下取整
					// var num = (Math.floor(val * 100) / 100) * 100
					// 四舍五入
					// var num = (Math.round(val * 100) / 100) * 100
					if(num > 1) {
						var resultNum = 100
					} else {
						var newVal = ((num * 100).toFixed(0)).slice(0,4).replace('.00', '')
						var resultNum = newVal.replace('.0', '')
						if(String(newVal).slice(-2) == '.0') {
							resultNum = newVal.replace('.0', '')
						} else {
							resultNum = newVal
						}
					}
					return resultNum + '%'
				}
			} else {
				return 0
			}
		}
    },
    watch: {
        'filters.timerData': function(val) {
            this.getBriefingListData()
        }
    },
    created(){
        // let routers = window.localStorage.router
        // ? JSON.parse(window.localStorage.router)
        // : [];
        // this.buttonList = getButtonList(this.$route.path, routers);
    },
    mounted() {
        this.getLastSomeDay()
        this.getDetailsData()
    }
}
</script>

<style lang="stylus" scoped>
    .examDetailsBox {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        font-size: 14px;
    }
    .detailTitle {
        font-size: 18px;
    }
    .detailNumber {
        margin: 15px 0 5px;
        font-size: 22px;
        font-weight: bold;
    }
    .timerSelect {
        text-align: right;
        margin: 5px 0;
    }
</style>