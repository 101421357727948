import { render, staticRenderFns } from "./ExamSituationAnalysis.vue?vue&type=template&id=a8e89b2e&scoped=true"
import script from "./ExamSituationAnalysis.vue?vue&type=script&lang=js"
export * from "./ExamSituationAnalysis.vue?vue&type=script&lang=js"
import style0 from "./ExamSituationAnalysis.vue?vue&type=style&index=0&id=a8e89b2e&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8e89b2e",
  null
  
)

export default component.exports